<template>
    <v-app>
        <v-navigation-drawer app v-model="drawer" floating width="320" style="height: 100%" :dark="shouldAlwaysBeDark">
            <template #prepend>
                <v-toolbar color="transparent" flat>
                    <app-logo-image :dark="shouldAlwaysBeDark"></app-logo-image>
                </v-toolbar>
                <nav-tenant-menu-list :value="me.tenant"></nav-tenant-menu-list>
                <entity-search-autocomplete
                    v-model="selectedSearchEntity"
                    solo
                    hide-details
                    flat
                    class="px-2 pb-2"
                    :types="['Service', 'Subscription', 'CloudAccount', 'Product', 'CustomView']"
                ></entity-search-autocomplete>
            </template>
            <nav-menu-list :value="me" :has-invalid-credentials="hasInvalidCredentials"></nav-menu-list>
            <template #append>
                <v-divider></v-divider>
                <v-btn
                    v-if="showSupportEmail && supportEmail"
                    color="info darken-2"
                    class="mt-2"
                    block
                    tile
                    depressed
                    @click="isSupportDialogOpen = true"
                >
                    <v-icon left>support_agent</v-icon>
                    Support
                </v-btn>
                <nav-user-menu-list :value="me"></nav-user-menu-list>
            </template>
        </v-navigation-drawer>
        <conditional-v-main>
            <!--TODO: where does this belong now?-->
            <fragment v-if="tenantStore.status">
                <div v-for="(activeUserAlert, i) in tenantStore.status.activeUserAlerts || []" :key="i">
                    <v-system-bar :color="activeUserAlert.level.toLowerCase()" height="auto">
                        <v-icon class="mr-2">{{ activeUserAlert.level.toLowerCase() }}</v-icon>
                        {{ activeUserAlert.alertText }}
                    </v-system-bar>
                </div>
                <v-system-bar color="info" v-if="tenantStore.status.hasActiveImports">
                    <v-progress-circular indeterminate :size="15" class="mr-2"></v-progress-circular>
                    <template v-if="tenantStore.cloudAccounts && groupedActiveImports.length">
                        {{ $t('views.mainView.loadingIndicator') }}
                        <span class="ml-3" color="info" v-for="(item, index) in groupedActiveImports" :key="index">
                            {{ item.cloudAccountName }} ({{ item.displayText }})
                        </span>
                    </template>
                    <span v-else>
                        {{ $t('views.mainView.loadingIndicator') }}
                    </span>
                </v-system-bar>
            </fragment>
            <router-view v-if="$auth.isAuthenticated" :me="me" @tenant-update="getMe"></router-view>
        </conditional-v-main>
        <v-dialog v-model="isSupportDialogOpen" width="500">
            <v-card color="#385F73" dark>
                <v-card-title class="text-h5">
                    <v-icon left>support_agent</v-icon>
                    Support</v-card-title
                >

                <v-card-subtitle class="mt-1">Having trouble? Email us for Support</v-card-subtitle>

                <v-card-actions>
                    <back-button @click="isSupportDialogOpen = false"></back-button>
                    <v-spacer></v-spacer>
                    <v-btn text :href="`mailto:${supportEmail}?subject=Support`">
                        <v-icon left>mail</v-icon>
                        Contact</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<i18n>
{
    "en": {
        "views.mainView.loadingIndicator": "We are currently importing usage for this tenant.",
        "views.mainView.loadFailedText": "There was an error loading your tenant status. Please refresh to continue."
    }
}
</i18n>

<script>
import NavMenuList from '@/components/View.Nav/NavMenuList';
import NavUserMenuList from '@/components/View.Nav/NavUserMenuList';
import NavTenantMenuList from '@/components/View.Nav/NavTenantMenuList';
import EventBus from '@/lib/EventBus.ts';
import ApiV2 from '@/lib/ApiV2.ts';
import Config from '@/lib/Config.ts';
import { waitFor } from '@/plugins/vue-wait';
import { toastError } from '@/components/Common/Toast.vue';
import { useTenantStore } from '@/stores/tenant.ts';
import { useAccountStore } from '@/stores/account.ts';
import { mapStores } from 'pinia';

export default {
    name: 'App',
    components: {
        NavMenuList,
        NavTenantMenuList,
        NavUserMenuList,
    },
    created() {
        this.getMe();
        this.getCredentialStatus();
        this.getTenantSummary();
        useAccountStore().loadAccountTenants();
        const interval = setInterval(() => {
            try {
                this.getTenantStatus();
            } catch (err) {
                clearInterval(interval);
                toastError(this.$t('views.mainView.loadFailedText'));
            }
        }, 30000);
        EventBus.$on('$identity.authenticated', this.onAuthentication);
        EventBus.$on('toggleDrawer', this.toggleDrawer);
        EventBus.$on('toggleFilters', this.toggleFilters);
    },
    destroyed() {
        EventBus.$off('$identity.authenticated', this.onAuthentication);
        EventBus.$off('toggleDrawer', this.toggleDrawer);
        EventBus.$off('toggleFilters', this.toggleFilters);
    },
    methods: {
        toggleDrawer() {
            this.drawer = !this.drawer;
        },
        toggleFilters() {
            this.drawer = this.showRightDrawer;
        },
        onAuthentication(isAuthenticated) {
            this.isAuthenticated = isAuthenticated;
        },
        getMe: waitFor('gettingMe', async function getMe() {
            this.me = await ApiV2.http.get('/api/accounts/me/profile').then((p) => p.data);
        }),
        getCredentialStatus: waitFor('getCredentialStatus', async function getCredentialStatus() {
            const credentialTypes = [
                'EaPortalApiCredential',
                'AwsIamUsageCredential',
                'AlibabaUsageCredential',
                'MicrosoftLoginCredential',
                'RhipeApiCredential',
                'GoogleJsonUsageCredential',
                'AzureActiveDirectoryApplicationCredential',
            ];
            this.hasInvalidCredentials = await ApiV2.http.get('/api/metadata/cloudaccounts').then(
                (r) =>
                    !!r.data.find((ca) => {
                        for (const key of credentialTypes) {
                            if (ca[key] && !ca[key].valid) {
                                return true;
                            }
                        }
                        return false;
                    })
            );
        }),
        getTenantSummary: waitFor('getTenantSummary', async function getTenantSummary() {
            const tenantSummary = await ApiV2.http.get('/api/metadata/tenants/current/summary').then((r) => r.data);
            if (tenantSummary) {
                this.tenantStore.setTenantDetails(tenantSummary);

                if (this.tenantStore.isSelfService && !this.tenantStore.hasActiveSubscription) {
                    this.$router.push('/settings/billing');
                    return;
                }

                if (this.tenantStore.hasActiveSubscription && this.tenantStore.numberOfActiveAccounts === 0) {
                    this.$router.push('/getting-started');
                    return;
                }

                // If there has not been a successful usage import yet for this tenant send them to the settings screen.
                if (!this.tenantStore.lastUsageDateForTenant) {
                    this.$router.push('/settings');
                    return;
                }
            }
        }),
        getTenantStatus: waitFor('getTenantStatus', async function getTenantStatus() {
            const status = await ApiV2.http.get('/api/metadata/tenants/current/summary/status').then((r) => r.data);
            this.tenantStore.setTenantDetails({ status });
        }),
    },
    data() {
        return {
            drawer: true,
            selectedSearchEntity: null,
            me: {},
            hasInvalidCredentials: false,
            isSupportDialogOpen: false,
            supportEmail: Config.get().SUPPORT_EMAIL,
            showSupportEmail: Config.isFeatureEnabled('ShowSupportButton') && Config.get().SUPPORT_EMAIL,
            shouldAlwaysBeDark: Config.get().SIDENAV_ALWAYS_DARK || undefined,
        };
    },
    computed: {
        ...mapStores(useTenantStore),
        groupedActiveImports() {
            const activeImports = this.tenantStore.activeImports;
            const groupedByYear = {};
            activeImports.forEach((importData) => {
                const { cloudAccountId, month, year } = importData;
                const accountName = this.tenantStore.cloudAccounts[cloudAccountId]?.name || 'Unknown';

                if (!groupedByYear[year]) {
                    groupedByYear[year] = {};
                }

                if (!groupedByYear[year][cloudAccountId]) {
                    groupedByYear[year][cloudAccountId] = { name: accountName, months: [] };
                }

                groupedByYear[year][cloudAccountId].months.push(month);
            });

            const result = [];

            Object.entries(groupedByYear).forEach(([year, accounts]) => {
                Object.entries(accounts).forEach(([_, data]) => {
                    const sortedMonths = data.months.sort((a, b) => a - b);
                    const monthRanges = [];

                    let rangeStart = sortedMonths[0];
                    let rangeEnd = rangeStart;

                    for (let i = 1; i < sortedMonths.length; i++) {
                        if (sortedMonths[i] === rangeEnd + 1) {
                            rangeEnd = sortedMonths[i];
                        } else {
                            monthRanges.push(
                                rangeStart === rangeEnd
                                    ? `${String(rangeStart).padStart(2, '0')}`
                                    : `${String(rangeStart).padStart(2, '0')}-${String(rangeEnd).padStart(2, '0')}`
                            );
                            rangeStart = sortedMonths[i];
                            rangeEnd = rangeStart;
                        }
                    }

                    monthRanges.push(
                        rangeStart === rangeEnd
                            ? `${String(rangeStart).padStart(2, '0')}`
                            : `${String(rangeStart).padStart(2, '0')}-${String(rangeEnd).padStart(2, '0')}`
                    );

                    result.push({
                        cloudAccountName: data.name,
                        displayText: `${monthRanges.join(', ')}/${year.slice(-2)}`,
                    });
                });
            });

            return result;
        },
    },
    watch: {
        selectedSearchEntity: {
            handler(val) {
                if (!val) return;
                if (val.Type === 'Service') {
                    this.$router.push(`/explore/services/${val.Id}`);
                } else if (val.Type === 'Subscription') {
                    this.$router.push(`/explore/subscriptions/${val.Id}`);
                } else if (val.Type === 'Tag') {
                    this.$router.push(`/explore/tags/${val.Id}`);
                } else if (val.Type === 'CloudAccount') {
                    this.$router.push(`/settings/cloudaccounts/${val.Id}`);
                } else if (val.Type === 'Product') {
                    this.$router.push(`/explore/products/${val.Id}`);
                } else if (val.Type === 'CustomView') {
                    this.$router.push(`/customviews/${val.Id}`);
                }

                this.$nextTick(() => {
                    this.selectedSearchEntity = null;
                });
            },
            deep: true,
            immediate: true,
        },
    },
};
</script>
